import React, { Component } from "react"
import NumberFormat from "react-number-format"
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"
import Card from "react-bootstrap/Card"

import { Container, Row, Col } from "react-bootstrap"
import ResultList from "../components/gamekeys/resultlist"

const API_URL = "https://gamekeysearch.de/gamekeys/api/steamTopseller"
class TopSeller extends Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
    }
  }

  componentDidMount() {
    console.log("Ermittle TopSeller")
    this.getTopSeller()
  }

  getTopSeller() {
    axios.get(`${API_URL}`).then(({ data }) => {
      this.setState({
        results: data,
      })
      console.log(data)
    })
  }

  render() {
    return (
      <Layout pageInfo={{ pageName: "topseller" }}>
        <SEO
          title="Topseller und Angebote"
          description="Was kosten aktuelle Steam Topseller bei anderen Keysellern. Hier hast du den schnellen Überblick, wie viel du sparen kannst!"
        />
        <Container>
          <Row>
            <Col>
              {" "}
              <h1>Steam Topseller im Angebot</h1>
              <p>
                Finde auf den ersten Blick heraus, welche Steam-Topseller wo
                aktuell am günstigsten zu kaufen sind.
              </p>
            </Col>
          </Row>
          {this.state.results.map(item => (
            <Row
              className="mb-3 pt-2 pb-2"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <Col sm={3}>
                <Card bg="light" style={{ width: "100%" }}>
                  <Card.Img
                    variant="top"
                    src={item.imageUrl}
                    style={({ maxHeight: "8rem" }, { height: "8rem" })}
                  />
                  <Card.Body>
                    <Card.Title>{item.gamename}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      <span>Aktueller Preis: </span>
                      <NumberFormat
                        value={item.price}
                        displayType={"text"}
                        decimalSeparator=","
                        thousandSeparator="."
                        decimalScale="2"
                        prefix="€"
                      />
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={9}>
                <ResultList products={item.keyseller}></ResultList>
              </Col>
            </Row>
          ))}
        </Container>
      </Layout>
    )
  }
}

export default TopSeller

import React, { Component, Fragment } from "react"
import Table from "react-bootstrap/Table"
import ResultItem from "./resultitem"

class ResultList extends Component {
  render() {
    console.log("Props:" + this.props.products)
    let productList = this.props.products
    console.log("Produkte:" + productList)
    return (
      <Fragment>
        <Table responsive hover borderless>
          <thead>
            <tr>
              <th width="8%"></th>
              <th width="55%">Name</th>
              <th width="10%">Keyseller</th>
              <th width="12%">Preis</th>
              <th width="5%">Ersparnis</th>
              <th width="10%"></th>
            </tr>
          </thead>
          <tbody>
            {productList.map(item => (
              <ResultItem key={item._id} item={item} />
            ))}
          </tbody>
        </Table>
      </Fragment>
    )
  }
}
export default ResultList
